import React, { FunctionComponent, ReactElement, useEffect } from 'react';

const GetADemo: FunctionComponent = (): ReactElement => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href =
        'https://poweredbypercent.zendesk.com/hc/en-us/requests/new?ticket_form_id=6528109463441';
    }
  }, []);

  return <div></div>;
};

export default GetADemo;
